import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="burn-out" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Erschöpfung / Stress`}</h1>
      <h3 {...{
        "id": "erschopfung--stress"
      }}>{`Erschöpfung / Stress`}</h3>
      <p>{`Erschöpft, müde, gestresst, ausgepowert, ausgebrannt`}</p>
      <p>{`Wer kennt das nicht? Jeder erlebt es hin und wieder. Stellen Sie sich vor, dieser Zustand hält an, ist nicht am nächsten Tag nach einem erholsamen Schlaf verschwunden. Anfängliche Missstimmungen werden begleitet und verstärkt durch körperliche Beschwerden, die sich ausweiten können auf alle drei Ebenen menschlichen Erlebens: Körper, Psyche, Geist.`}</p>
      <p>{`Unbehandelt kann die Erschöpfung ins Burn-out-Syndrom führen. Nur eine ganzheitliche Medizin, wie wir sie in Form der Naturheilkunde finden, bietet die vielfältigen Möglichkeiten umfassender kausaler Therapieansätze.`}</p>
      <p>{`Unser „Therapiebaukasten“ bei Erschöpfungs-Syndromen: `}<a parentName="p" {...{
          "href": "/therapien/vitamin-c-infusion"
        }}>{`Vitamin-C-Infusion`}</a>{` als „1.Hilfe“, `}<a parentName="p" {...{
          "href": "/therapien/schroepfmassage"
        }}>{`Schröpfmassage`}</a>{` zur vegetativen Stabilisierung, Coaching/Lebensberatung, Ernährungsberatung (basische Ernährung), Entspannungstechniken, biologische Medikamente zur Leistungssteigerung und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/immunstaerkung"
        }}>{`Immunstärkung`}</a>{`.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      